<script setup lang="ts">
defineProps<{ preventNavigate?: boolean }>();

const localPath = useLocalePath();
const { type, rankLeague } = useStatusSystem();
const { data: appInitData } = useAppInitData();

const isSeasonActive = computed(() => appInitData.value?.season?.isActive);
const progressTask = computed(() => {
	const calcProgress = (progress: number) => 314.159 - progress * Math.PI;

	if (!rankLeague.value || isSeasonActive.value) {
		return calcProgress(0);
	}

	if (!rankLeague.value?.progressData?.level) {
		return calcProgress(2);
	}

	const { pointsReceived, pointsNeedForNextLevel } = rankLeague.value?.progressData?.level;
	const currentProgress = (pointsReceived / (pointsNeedForNextLevel + pointsReceived)) * 100;

	if (!currentProgress) {
		return calcProgress(2);
	}

	return calcProgress(currentProgress);
});
</script>
<template>
	<NuxtLink :to="preventNavigate ? undefined : localPath('/game/')" class="avatar-progress">
		<NuxtImg
			:src="`/nuxt-img/status-system/${isSeasonActive ? 'freeze' : 'slides'}/${type}.png`"
			alt="status"
			format="webp"
			width="40"
			height="32"
		/>
		<NuxtIcon name="40/progress" filled />
		<slot />
	</NuxtLink>
</template>
<style scoped lang="scss">
.avatar-progress {
	display: inline-flex;
	position: relative;
	width: 48px;
	height: 48px;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	.nuxt-icon {
		display: inline-flex;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		font-size: 48px;

		&:deep(svg) {
			margin: 0;

			.progress-circle {
				stroke-dashoffset: v-bind(progressTask);
			}
		}
	}
}
</style>
